import React from 'react';
import { motion } from 'framer-motion';

import { MotionDiv } from '../../animations/components';
import { animationContainer, fadeInUp } from '../../animations/variants';
import { ScContainer } from '../../components/container/styled';
import { ScParagraph } from '../../components/Paragraph/styled';
import { ScMainTitle } from '../../components/Title/styled';
import useContentAnimation from '../../hooks/use-content-animation';

import { ScNurseryHeroTextWrapper, ScNurseryHeroWrapper } from './styled';

export const NurseryHero = () => {
	const { ref, controls } = useContentAnimation();

	return (
		<motion.section initial="hidden" animate={controls} variants={animationContainer}>
			<ScContainer>
				<ScNurseryHeroWrapper ref={ref}>
					<ScNurseryHeroTextWrapper>
						<MotionDiv variants={fadeInUp}>
							<ScMainTitle centered>Not your average workplace nursery scheme</ScMainTitle>
						</MotionDiv>
						<MotionDiv variants={fadeInUp}>
							<ScParagraph centered>
								We're a workplace nursery scheme with a difference, designed with busy nursery managers in mind. As well as saving parents money, by allowing them to salary sacrifice their nursery bills, Gogeta Nursery benefits your nursery too. For every parent that pays their fees using Gogeta, you get an additional financial contribution of 12.5% of parent’s nursery fees to allocate towards nursery improvements and equipment.
							</ScParagraph>
						</MotionDiv>
					</ScNurseryHeroTextWrapper>
				</ScNurseryHeroWrapper>
			</ScContainer>
		</motion.section>
	);
};
