import React from 'react';
import { motion } from 'framer-motion';
import { useTheme } from 'styled-components';

import { MotionDiv } from '../../animations/components';
import { animationContainer, cardUp } from '../../animations/variants';
import { ScContainer } from '../../components/container/styled';
import { ScParagraph } from '../../components/Paragraph/styled';
import { ScTitle } from '../../components/Title/styled';
import useContentAnimation from '../../hooks/use-content-animation';

import { ScNurseryInfoItem, ScNurseryInfoItemsWrapper, ScNurseryInfoWrapper } from './styled';

const items = [
	{
		title: 'What is Gogeta Nursery?',
		text: 'We’re a salary sacrifice benefit scheme that employers can offer to staff, letting them pay for nursery fees out of their gross salary, before tax and NI contributions. As well as saving parents lots of money, Gogeta Nursery benefits your nursery too. For every parent that pays their fees using Gogeta, you get an additional financial contribution of 12.5% of parent’s nursery fees per month.',
	},
];

export const ProviderNurseryInfo = () => {
	const theme = useTheme();
	const { ref, controls } = useContentAnimation();

	return (
		<motion.section initial="hidden" animate={controls} variants={animationContainer}>
			<ScContainer>
				<ScNurseryInfoWrapper>
					<ScNurseryInfoItemsWrapper ref={ref}>
						{items.map((item) => (
							<MotionDiv key={item.title} variants={cardUp}>
								<ScNurseryInfoItem>
									<ScTitle centered color={theme.colors.strongWhite}>
										{item.title}
									</ScTitle>
									<ScParagraph centered color={theme.colors.strongWhite}>
										{item.text}
									</ScParagraph>
								</ScNurseryInfoItem>
							</MotionDiv>
						))}
					</ScNurseryInfoItemsWrapper>
				</ScNurseryInfoWrapper>
			</ScContainer>
		</motion.section>
	);
};
